<template>
  <div class="publication-deposits">
    <page-header :title="title" icon="fa fa-layer-group" :links="links"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe">
      <b-row>
        <b-col v-if="!selectedDeposit">
          <x-table
            class="small"
            id="deposits"
            :columns="depositColumns"
            :items="depositItems"
            :show-footer="true"
            :show-counter="true"
            verbose-name="dépôt"
          ></x-table>
        </b-col>
        <b-col v-if="selectedDeposit">
          <div v-if="htmlHeader" class="hide-here">
            <div v-html="htmlHeader"></div>
          </div>
          <div style="margin-bottom: 5px;">
            <b-row>
              <b-col cols="7">
                <b>
                  Dépôt n°{{ selectedDeposit.depositNumber }} - {{ selectedDeposit.depositOn | dateToString }}
                </b>
                <div>
                  <b>
                    <a href @click.prevent="showEntitySidebar(selectedDeposit.entity)">
                      {{ selectedDeposit.entity.name }}
                    </a>
                  </b>
                </div>
                <div>
                  {{ selectedDeposit.entity.fullAddress() }}
                </div>
              </b-col>
              <b-col cols="2">
                <span v-for="invoice of invoices" :key="invoice.id">
                  <invoice-badge :invoice="invoice" basic-label :with-br="false" font-size="20px"></invoice-badge>
                </span>
              </b-col>
              <b-col cols="1">
                <div v-if="invoiceMe" class="no-print">
                  <a href class="btn btn-secondary btn-sm btn-block" @click.prevent="doInvoice()">
                    <i class="fa fa-file-invoice"></i> Facturer
                  </a>
                </div>
                <div v-if="invoiceMe && payMe" style="margin-top: 5px;"></div>
                <div v-if="payMe" class="no-print">
                  <a href class="btn btn-secondary btn-sm btn-block" @click.prevent="payInvoices()">
                    <i class="fa fa-money-bill"></i> Payer
                  </a>
                </div>
              </b-col>
              <b-col cosl="2" class="text-right no-print">
                <a href @click.prevent="resetDeposit()" class="btn btn-secondary btn-sm">
                  <i class="fa fa-chevron-left"></i> Retour aux dépôts
                </a>
              </b-col>
            </b-row>
          </div>
          <div>
            <div>
              <counter-label :counter="totalCopies" label="exemplaire"></counter-label>
            </div>
            <x-table
              class="small"
              id="copies"
              :columns="copyColumns"
              :items="copyItems"
              :show-footer="true"
            ></x-table>
            <div v-if="subscriptors.length" style="margin-top: 20px">
              <x-table
                class="small"
                id="subscriptors"
                :columns="subscriptorColumns"
                :items="subscriptorItems"
                :show-footer="true"
                :show-counter="true"
                verbose-name="abonné"
              ></x-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <add-publication-copy-modal
      modal-id="bv-add-deposit-publication-copy"
      :deposit="selectedDeposit"
      v-if="hasPerm('publications.add_publicationcopy')"
      @done="loadCopies()"
    ></add-publication-copy-modal>
    <edit-publication-copy-modal
      modal-id="bv-edit-deposit-publication-copy"
      :publication-copy="selectedCopy"
      v-if="hasPerm('publications.change_publicationcopy')"
      @done="loadCopies()"
    ></edit-publication-copy-modal>
    <add-publication-deposit-modal
      modal-id="bv-add-publication-deposit"
      v-if="hasPerm('publications.add_publicationdeposit')"
      @done="loadPublicationDeposits()"
    ></add-publication-deposit-modal>
    <confirm-modal
      name="delete-publication-copy"
      icon="fa fa-trash"
      :object="selectedCopy"
      title="Suppression d'un exemplaire"
      :text="deleteCopyText"
      :text2="deleteCopyText2"
      @confirmed="onConfirmDeleteCopy"
    ></confirm-modal>
    <confirm-modal
      name="delete-subscriptor"
      icon="fa fa-trash"
      :object="selectedSub"
      title="Suppression d'un abonné"
      :text="deleteSubText"
      @confirmed="onConfirmDeleteSubscriptor"
    ></confirm-modal>
    <confirm-modal
      name="do-deposit-invoice"
      icon="fa fa-file-invoice"
      :object="selectedDeposit"
      title="Facturer le depôt"
      text="Les ventes seront facturées. Continuer?"
      @confirmed="onConfirmDoInvoice()"
    ></confirm-modal>
    <payment-modal
      modal-id="pay-copy-modal"
      :entity="paymentEntity"
      :invoice="paymentInvoice"
      @paid="loadCopies()"
    >
    </payment-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import store from '@/store'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import PageHeader from '@/components/Layout/PageHeader'
import XTable from '@/components/Controls/Table/Table.vue'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import AddPublicationDepositModal from '@/components/Publications/AddPublicationDepositModal.vue'
import AddPublicationCopyModal from '@/components/Publications/AddPublicationCopyModal.vue'
import EditPublicationCopyModal from '@/components/Publications/EditPublicationCopyModal.vue'
import PaymentModal from '@/components/Payments/PaymentModal.vue'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import { makePublicationDeposit, makePublicationCopy, makeSubscriptor } from '@/types/publications'
import { distinct } from '@/utils/arrays'
import { slugify } from '@/utils/strings'
import { sum } from '@/utils/math'

export default {
  name: 'publication-deposits',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    InvoiceBadge,
    AddPublicationDepositModal,
    PaymentModal,
    ConfirmModal,
    AddPublicationCopyModal,
    EditPublicationCopyModal,
    XTable,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'publication-deposits',
      selectedDeposit: null,
      deposits: [],
      copies: [],
      htmlHeader: '',
      subscriptors: [],
      subscriptorColumns: [],
      copyColumns: [],
      depositColumns: [],
      paymentEntity: null,
      selectedCopy: null,
      publication: null,
      selectedSub: null,
    }
  },
  watch: {
    routePath: function() {
      if (this.routePath) {
        this.selectedDeposit = null
      }
    },
  },
  computed: {
    routePath() {
      if (this.$route.name === 'publications-deposits') {
        return !this.$route.query.no
      }
      return false
    },
    title() {
      return 'Depôts ' + store.getters.config.publicationTitle
    },
    copyItems() {
      return this.copies.map(this.makeCopyItem)
    },
    subscriptorItems() {
      return this.subscriptors.map(this.makeSubscriptorItem)
    },
    canEdit() {
      return this.hasPerm('publications.change_publicationdeposit')
    },
    links() {
      const canExcel = !this.isLoading(this.loadingName)
      const canAddCopy = !this.isLoading(this.loadingName) && !!this.selectedDeposit
      const canAddDeposit = !this.isLoading(this.loadingName) && !this.selectedDeposit
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.loadingName)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (!canExcel) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (canAddDeposit && this.hasPerm('publications.add_publicationdeposit')) {
        links.push(
          {
            id: 3,
            label: 'Nouveau dépôt',
            callback: () => {
              this.$bvModal.show('bv-add-publication-deposit')
            },
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      if (canAddCopy && this.hasPerm('publications.add_publicationcopy')) {
        links.push(
          {
            id: 4,
            label: 'Nouvel exemplaire',
            callback: () => {
              this.$bvModal.show('bv-add-deposit-publication-copy')
            },
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      return links
    },
    deleteCopyText() {
      let text = 'Êtes-vous sûr de supprimer l\'exemplaire'
      if (this.selectedCopy) {
        text += ' ' + this.selectedCopy.publication.shortName()
        text += ' du ' + dateToString(this.selectedCopy.createdOn, 'DD/MM/YYYY') + '?'
      }
      return text
    },
    deleteCopyText2() {
      let text = ''
      if (this.selectedCopy) {
        if (this.selectedCopy.sale && this.selectedCopy.sale.invoice) {
          text += 'La vente sera annulée'
          if (this.selectedCopy.sale.invoice.toBePaidPrice() < this.selectedCopy.sale.price) {
            text += ' et un avoir sera créé'
          }
        }
      }
      return text
    },
    deleteSubText() {
      let text = 'Êtes-vous sûr de supprimer l\'abonné'
      if (this.selectedSub) {
        text += ' de ' + this.selectedSub.subscription.shortName()
        text += ' du ' + dateToString(this.selectedSub.createdOn, 'DD/MM/YYYY') + '?'
      }
      return text
    },
    depositItems() {
      return this.deposits.map(this.makeDepositItem)
    },
    invoices() {
      return distinct(this.copies.filter(elt => elt.sale && elt.sale.invoice).map(elt => elt.sale.invoice))
    },
    paymentInvoice() {
      const notPaid = this.invoices.filter(elt => elt.toBePaidPrice() > 0)
      if (notPaid.length === 1) {
        return notPaid[0]
      }
      return null
    },
    payMe() {
      return this.invoices.filter(invoice => invoice.toBePaidPrice()).length > 0
    },
    invoiceMe() {
      return this.copies.filter(
        elt => (elt.totalPrice && !elt.sale) || (elt.sale && !elt.sale.invoice)
      ).length > 0
    },
    totalCopies() {
      return sum(this.copies.map(copy => copy.quantity))
    },
  },
  async mounted() {
    this.copyColumns = this.getCopyColumns()
    this.depositColumns = this.getDepositColumns()
    this.subscriptorColumns = this.getSubscriptorColumns()
    await this.loadPublicationDeposits()
    let depositNumber = this.$route.query.no
    if (depositNumber) {
      const index = this.deposits.map(elt => elt.depositNumber).indexOf(+depositNumber)
      if (index >= 0) {
        this.setDeposit(this.deposits[index])
      }
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    getCopyColumns() {
      const cols = [
        {
          name: 'createdOn',
          label: 'le',
          dateFormat: 'LL',
        },
        {
          name: 'publicationType',
          label: 'Titre',
        },
        {
          name: 'publicationName',
          label: 'Exemplaire',
        },
        {
          name: 'quantity',
          label: 'Quantité',
          number: true,
          colFooterSum: true,
        },
        {
          name: 'publicPrice',
          label: 'Prix public',
          currency: true,
        },
        {
          name: 'unitPrice',
          label: 'Prix déposant',
          currency: true,
        },
        {
          name: 'discount',
          label: 'Réduction',
          currency: true,
        },
        {
          name: 'sendingPrice',
          label: 'Prix envoi',
          currency: true,
        },
        {
          name: 'totalPrice',
          label: 'Prix total',
          currency: true,
          colFooterSum: true,
        },
        {
          name: 'invoicing',
          label: '',
          hideFilter: false,
          isLink: item => {
            return (item.invoice)
          },
          noPrint: true,
          linkUrl: item => {
            if (item.invoice) {
              return router.resolve(
                { name: 'invoice-detail', params: { invoiceId: '' + item.invoice.id, }, }
              ).href
            }
          },
          contentCallback: (col, item) => {
            if (item.invoice) {
              return '<span class="badge ' + item.invoice.getStyle() + '">' + item.invoicing + '</span>'
            } else {
              return '<span class="badge todo-invoice">' + item.invoicing + '</span>'
            }
          },
        }
      ]
      if (this.hasPerm('publications.change_publicationcopy')) {
        cols.push(
          {
            name: 'edit',
            label: '  ',
            hideFilter: true,
            alignRight: true,
            maxWidth: '25px',
            isLink: item => {
              return (item)
            },
            onClick: item => {
              this.onUpdateCopy(item)
            },
            noPrint: true,
          }
        )
      }
      if (this.hasPerm('publications.delete_publicationcopy')) {
        cols.push(
          {
            name: 'delete',
            label: '  ',
            hideFilter: true,
            alignRight: true,
            maxWidth: '25px',
            isLink: item => {
              return (item)
            },
            onClick: item => {
              this.onDeleteCopy(item)
            },
            noPrint: true,
          }
        )
      }
      return cols
    },
    getSubscriptorColumns() {
      const cols = [
        {
          name: 'createdOn',
          label: 'le',
          dateFormat: 'LL',
        },
        {
          name: 'publicationType',
          label: 'Titre',
        },
        {
          name: 'subscriptionName',
          label: 'Abonnement',
        },
        {
          name: 'quantity',
          label: 'Quantité',
          number: true,
          colFooterSum: true,
        },
        {
          name: 'unitPrice',
          label: 'Prix unitaire',
          currency: true,
        },
        {
          name: 'discount',
          label: 'Réduction',
          currency: true,
        },
        {
          name: 'sendingPrice',
          label: 'Prix envoi',
          currency: true,
        },
        {
          name: 'totalPrice',
          label: 'Prix total',
          currency: true,
          colFooterSum: true,
        },
        {
          name: 'invoicing',
          label: '',
          hideFilter: false,
          isLink: item => {
            return (item.invoice)
          },
          linkUrl: item => {
            if (item.invoice) {
              return router.resolve(
                { name: 'invoice-detail', params: { invoiceId: '' + item.invoice.id, }, }
              ).href
            }
          },
          contentCallback: (col, item) => {
            if (item.invoice) {
              return '<span class="badge ' + item.invoice.getStyle() + '">' + item.invoicing + '</span>'
            } else {
              return '<span class="badge todo-invoice">' + item.invoicing + '</span>'
            }
          },
          noPrint: true,
        }
      ]
      if (this.hasPerm('publications.delete_subscriptor')) {
        cols.push(
          {
            name: 'delete',
            label: '  ',
            hideFilter: true,
            alignRight: true,
            maxWidth: '25px',
            isLink: item => {
              return (item)
            },
            onClick: item => {
              this.onDeleteSubscriptor(item)
            },
            noPrint: true,
          }
        )
      }
      return cols
    },
    getDepositColumns() {
      return [
        {
          name: 'depositNumber',
          label: 'N°',
          isLink: item => {
            return (item)
          },
          onClick: item => {
            this.setDeposit(item.deposit)
          },
        },
        {
          name: 'entityName',
          label: 'Nom',
          onClick: item => {
            this.setDeposit(item.deposit)
          },
          isLink: item => {
            return (item.entity.id)
          },
        },
        {
          name: 'city',
          label: 'Ville',
        },
        {
          name: 'depositOn',
          label: 'le',
          dateFormat: 'LL',
        },
        {
          name: 'copiesCount',
          label: 'Nb exemplaires',
          number: true,
        },
        {
          name: 'totalPrice',
          label: 'Tarif',
          currency: true,
        },
        {
          name: 'invoicePrice',
          label: 'Facturé',
          currency: true,
        },
        {
          name: 'paidPrice',
          label: 'Payé',
          currency: true,
        }
      ]
    },
    makeCopyItem(copy) {
      let invoice = null
      let invoicing = null
      if ((!copy.schoolYear.isArchived) && copy.sale) {
        invoice = copy.sale.invoice
        invoicing = invoice ? invoice.status : 'à facturer'
      }
      return {
        id: copy.id,
        publicationType: copy.publication.publicationType.name,
        publicationName: copy.publication.shortName(),
        createdOn: copy.createdOn,
        totalPrice: copy.totalPrice,
        publicPrice: copy.publication.price,
        unitPrice: copy.publication.depositPrice,
        discount: copy.discount,
        sendingPrice: copy.discount,
        sending: copy.sending ? 'Oui' : '',
        type: copy.deposit ? 'Dépôt' : '',
        quantity: copy.quantity,
        edit: '<i class="fa fa-edit"></i>',
        delete: '<i class="fas fa-trash"></i>',
        copy: copy,
        invoice: invoice,
        invoicing: invoicing,
      }
    },
    makeSubscriptorItem(obj) {
      let invoice = null
      let invoicing = null
      if ((!obj.schoolYear.isArchived) && obj.sale) {
        invoice = obj.sale.invoice
        invoicing = invoice ? invoice.status : 'à facturer'
      }
      return {
        id: obj.id,
        publicationType: obj.subscription.publicationType.name,
        publicationName: obj.subscription.shortName(),
        createdOn: obj.createdOn,
        totalPrice: obj.totalPrice,
        unitPrice: obj.unitPrice,
        discount: obj.discount,
        sendingPrice: obj.discount,
        sending: obj.sending ? 'Oui' : '',
        quantity: obj.quantity,
        delete: '<i class="fas fa-trash"></i>',
        copy: obj,
        invoice: invoice,
        invoicing: invoicing,
      }
    },
    makeDepositItem(deposit) {
      return {
        id: deposit.id,
        depositNumber: deposit.depositNumber,
        entityName: deposit.entity.name,
        entity: deposit.entity,
        address: deposit.entity.addressText(),
        zipCode: deposit.entity.zipCode,
        city: deposit.entity.city.name,
        depositOn: deposit.depositOn,
        deposit: deposit,
        copiesCount: deposit.copiesCount,
        totalPrice: deposit.totalPrice,
        paidPrice: deposit.paidPrice,
        invoicePrice: deposit.invoicePrice,
      }
    },
    async init() {
    },
    async loadPublicationDeposits() {
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/publication-deposits/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.deposits = resp.data.map(makePublicationDeposit)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadCopies() {
      if (this.selectedDeposit) {
        this.copies = []
        this.startLoading(this.loadingName)
        try {
          let url = '/publications/api/publication-deposit-copies/' + this.selectedDeposit.id + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.copies = resp.data.copies.map(makePublicationCopy)
          this.subscriptors = resp.data.subscriptors.map(makeSubscriptor)
          this.htmlHeader = resp.data['html_header']
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    getDocName() {
      if (this.selectedDeposit) {
        return 'depot' + this.selectedDeposit.depositNumber + ' ' + this.selectedDeposit.entity.name
      } else {
        return 'depots'
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify(this.getDocName())
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = slugify(this.getDocName())
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onUpdateCopy(item) {
      this.selectedCopy = item.copy
      this.$bvModal.show('bv-edit-deposit-publication-copy')
    },
    onDeleteCopy(item) {
      this.selectedCopy = item.copy
      this.$bvModal.show('bv-confirm-modal:delete-publication-copy')
    },
    async onConfirmDeleteCopy(event) {
      const copy = event.object
      try {
        const url = '/publications/api/delete-publication-copy/' + copy.id + '/'
        const backendApi = new BackendApi('delete', url)
        await backendApi.callApi()
        await this.addSuccess('L\'exemplaire a été supprimé')
        await this.loadCopies()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDeleteSubscriptor(item) {
      this.selectedSub = item.subscriptor
      this.$bvModal.show('bv-confirm-modal:delete-subscriptor')
    },
    async onConfirmDeleteSubscriptor(event) {
      const sub = event.object
      try {
        const url = '/publications/api/delete-subscriptor/' + sub.id + '/'
        const backendApi = new BackendApi('delete', url)
        await backendApi.callApi()
        await this.addSuccess('L\'abonné a été supprimé')
        await this.loadCopies()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    doInvoice() {
      this.$bvModal.show('bv-confirm-modal:do-deposit-invoice')
    },
    async onConfirmDoInvoice() {
      if (this.selectedDeposit) {
        try {
          const url = '/publications/api/update-publication-deposit/' + this.selectedDeposit.id + '/'
          const backendApi = new BackendApi('post', url)
          await backendApi.callApi({ 'create_invoice': true, })
          await this.addSuccess('Le dépôt a été facturé')
          await this.loadCopies()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    payInvoices() {
      if (this.selectedDeposit) {
        this.paymentEntity = this.selectedDeposit.entity
        this.$nextTick(
          () => {
            this.$bvModal.show('pay-copy-modal')
          }
        )
      }
    },
    resetDeposit() {
      this.selectedDeposit = null
      router.push({ path: this.$route.path, query: {}, })
      this.copies = []
      this.subscriptors = []
      this.loadPublicationDeposits()
    },
    setDeposit(deposit) {
      this.selectedDeposit = deposit
      this.loadCopies()
      router.push({ path: this.$route.path, query: { no: deposit.depositNumber, }, })
    },
  },
}
</script>

<style scoped lang="less">
.articles-header {
  padding:  10px;
  background: #ccc;
}
.article-line {
  margin-bottom: 20px;
  padding: 10px;
  background: #eee;
}
.tab-content {
  margin-top: 5px;
}
</style>
