<template>
  <span class="edit-publication-copy" v-if="hasPerm('publications.change_publicationcopy')">
    <b-modal dialog-class="modal-lg"
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-book-reader"></i> Modifier un exemplaire</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row v-if="hasSale">
        <b-col>
          <div v-if="willCreateCredit" class="warning-text" style="margin-bottom: 10px;">
            La vente a déjà été facturée et payée. Elle sera annulée et remboursée par un avoir.
            <div v-if="quantity">Une nouvelle vente sera créé en remplacement</div>
          </div>
          <div v-else-if="publicationCopy.sale.invoice" class="warning-text" style="margin-bottom: 10px;">
            La vente a déjà été facturée. Elle sera annulée.
            <div v-if="quantity">Une nouvelle vente sera créé en remplacement</div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            id="publicationType"
            description="Type"
            :disabled="publicationTypes.length <= 1"
          >
            <b-form-select
              id="publicationType"
              v-model="selectedPublicationType"
            >
              <b-form-select-option :value="itm" v-for="itm of publicationTypes" :key="itm.id">
                {{ itm.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
            id="publication"
            description="Publication"
          >
            <b-form-select
              id="publication"
              v-model="selectedPublication"
              :disabled="publications.length <= 1"
            >
              <b-form-select-option :value="pub" v-for="pub of publications" :key="pub.id">
                {{ pub.shortName() }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="quantity-group"
            description="Quantité"
            label-for="quantity"
          >
            <b-form-input type="number" step=1 min=1 id="quantity" v-model="quantity"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="unitPrice"
            description="Prix unitaire"
            label-for="unitPrice"
            required
          >
            <decimal-input id="unitPrice" v-model="unitPrice"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-checkbox v-model="sending" id="sending">
            Envoi
          </b-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="sendingPrice"
            description="Tarif envoi"
            label-for="sendingPrice"
          >
            <decimal-input :disabled="!sending" id="sendingPrice" v-model="sendingPrice"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="discount"
            description="Réduction"
            label-for="discount"
            required
          >
            <decimal-input id="discount" v-model="discount"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="totalPrice"
            description="Prix total"
            label-for="totalPrice"
            required
          >
            <decimal-input disabled id="totalPrice" v-model="totalPrice"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import { makePublicationCopy } from '@/types/publications'
import { BackendApi } from '@/utils/http'

export default {
  name: 'edit-publication-copy-modal',
  components: { DecimalInput, },
  mixins: [BackendMixin],
  props: {
    publicationCopy: Object,
    modalId: {
      type: String,
      default: 'bv-edit-publication-copy',
    },
  },
  data() {
    return {
      totalPrice: 0,
      unitPrice: 0,
      discount: 0,
      sending: 0,
      sendingPrice: 0,
      errorText: '',
      quantity: 1,
      selectedPublicationType: null,
      selectedPublication: null,
      publicationTypes: [],
    }
  },
  computed: {
    publications() {
      if (this.publicationCopy) {
        return [this.publicationCopy.publication]
      }
      return []
    },
    publication() {
      if (this.publicationCopy) {
        return this.publicationCopy.publication
      }
      return null
    },
    hasSale() {
      if (this.publicationCopy) {
        const sale = this.publicationCopy.sale
        return sale && sale.id
      }
      return false
    },
    willCreateCredit() {
      if (this.publicationCopy) {
        const sale = this.publicationCopy.sale
        return (
          sale && sale.invoice && (sale.invoice.toBePaidPrice() < sale.price)
        )
      }
      return false
    },
  },
  watch: {
    unitPrice: function() { this.updatePrice() },
    quantity: function() { this.updatePrice() },
    sendingPrice: function() { this.updatePrice() },
    sending: function() { this.updatePrice() },
    discount: function() { this.updatePrice() },
    publication: function() {
      this.init()
    },
    publicationCopy: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
      if (this.publicationCopy && this.publication) {
        this.allPublications = [this.publication]
        this.publicationTypes = [this.publication.publicationType]
        this.selectedPublication = this.allPublications[0]
        this.selectedPublicationType = this.publicationTypes[0]
        this.unitPrice = this.publication.price
        this.quantity = this.publicationCopy.quantity
        this.sending = this.publicationCopy.sending
        this.sendingPrice = this.publicationCopy.sendingPrice
        this.discount = this.publicationCopy.discount
        this.updatePrice()
      }
    },
    isDisabled() {
      return (this.quantity <= 0)
    },
    async onSave() {
      this.errorText = ''
      if (this.publicationCopy) {
        try {
          const url = '/publications/api/update-publication-copy/' + this.publicationCopy.id + '/'
          const backendApi = new BackendApi('post', url)
          let entity = null
          let data = {
            discount: this.discount,
            quantity: this.quantity,
            total_price: this.totalPrice,
            sending_price: this.sendingPrice,
            sending: this.sending,
          }
          const resp = await backendApi.callApi(data)
          this.$bvModal.hide(this.modalId)
          this.$emit('done', makePublicationCopy(resp.data))
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    updatePrice() {
      let sendingPrice = 0
      if (this.sending) {
        sendingPrice = this.sendingPrice
      }
      this.totalPrice = this.quantity * this.unitPrice + sendingPrice - this.discount
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less">
</style>
